<template>
  <b-container fluid class="full-height">
    <b-row class="full-height">
      <b-col class="left-side">
        <div class="login">
          <LoginComponent />
        </div>
        <div class="logo">
          <img src="@/assets/img/logoClara.png" />
        </div>
        <div class="max-width">
          <div class="message">
            <div class="title">
              <h2>{{ $t('views.login_green.text_1141') }}</h2>
              <h2 class="greenn">Greenn</h2>
            </div>
            <div class="text">
              <img src="@/assets/img/foto.png" alt="login" />
              <p>
                {{ $t('views.login_green.text_1142') }}
              </p>
            </div>
            <button @click.prevent="handlerLogins('1')">Entrar</button>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import LoginComponent from "@/components/login/LoginComponent";
export default {
  name: "Login",
  components: {
    LoginComponent,
  },
  data: () => ({
    showLogin: false,
  }),
  emits: ["handlerLoginRegister"],
  methods: {
    handlerLogins(id) {
      this.$root.$emit("handlerLoginRegister", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.left-side {
  background-image: url("~@/assets/img/fundoLogin.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .login {
    width: 30%;
    position: absolute;
    right: 0;
  }
  .logo {
    img {
      width: 220px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
    }
  }
  .max-width {
    max-width: 75%;
    .message {
      width: 420px;
      float: right;
      height: 85vh;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .title {
        h2 {
          color: #fff;
          font-family: "Montserrat";
          font-size: 55px;
          font-weight: 800;
          line-height: 0.9;
        }
        .greenn {
          color: #00ac69;
          margin-bottom: 20px;
        }
      }
      .text {
        margin-bottom: 30px;
        img {
          width: 100px;
          float: left;
          margin-right: 20px;
        }
        p {
          color: #fff;
          font-family: "Montserrat";
          font-weight: 600;
        }
      }
      button {
        background-color: #f18534;
        color: #222;
        font-family: "Montserrat";
        font-weight: 800;
        border: 0;
        padding: 12px;
        border-radius: 20px;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          background-color: #e27521;
          transition-duration: 0.5s;
        }
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .left-side {
    background-image: url("~@/assets/img/fundoLogin.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .login {
      width: 40%;
      position: absolute;
      right: 0;
    }
    .logo {
      img {
        width: 220px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
      }
    }
    .max-width {
      max-width: 90%;
      .message {
        .title {
          h2 {
            font-size: 45px;
          }
        }
        .text {
          img {
            width: 80px;
          }
          p {
            font-weight: 500;
            font-size: 14px;
          }
        }
        button {
          width: 80%;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .left-side {
    background-image: url("~@/assets/img/fundoMobile.jpg");
    background-position: initial;
    .login {
      width: 100%;
    }
    .logo {
      img {
        width: 180px;
      }
    }
    .max-width {
      max-width: 100%;
      .message {
        text-align: center;
        margin-top: 10%;
        width: 100%;
        float: initial;
        height: 85vh;
        display: block;
        .title {
          h2 {
            font-size: 45px;
          }
          .greenn {
            color: #00ac69;
            margin-bottom: 30px;
          }
        }
        .text {
          width: 100%;
          margin-bottom: 20px;
          img {
            width: 100px;
            float: initial;
            display: inline;
            margin-right: 0;
            margin-bottom: 20px;
          }
          p {
            width: 50%;
            margin: 0 auto;
            font-weight: 500;
          }
        }
        button {
          width: 40%;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .left-side {
    background-image: url("~@/assets/img/fundoMobile.jpg");
    background-position: initial;
    .login {
      width: 100%;
    }
    .logo {
      img {
        width: 150px;
      }
    }
    .max-width {
      max-width: 100%;
      .message {
        text-align: center;
        margin-top: 5%;
        width: 100%;
        float: initial;
        height: 85vh;
        display: block;
        .title {
          h2 {
            font-size: 35px;
          }
          .greenn {
            color: #00ac69;
            margin-bottom: 20px;
          }
        }
        .text {
          width: 100%;
          margin-bottom: 20px;
          img {
            width: 70px;
            float: initial;
            display: inline;
            margin-right: 0;
            margin-bottom: 10px;
          }
          p {
            width: 80%;
            margin: 0 auto;
            font-weight: 500;
          }
        }
        button {
          width: 70%;
        }
      }
    }
  }
}
</style>
